import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import IUserGroup from '@interfaces/IUserGroup'
import $UserGroup from '@services/UserGroup'
import { Link } from 'react-router-dom'
import Table from '@components/Table/Table'
import { useSortBy, useTable } from 'react-table'
import useUserGroupUserColumns from '@hooks/useUserGroupUserColumns'
import IUser from '@interfaces/IUser'
import { Spinner } from 'react-bootstrap'

const UserGroup: React.FC = () => {
  const [userGroup, setUserGroup] = useState<IUserGroup | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const SweetAlert = withReactContent(Swal)

  const { id } = useParams()

  const columns = useUserGroupUserColumns((user) => {
    if (userGroup) {
      SweetAlert.fire({
        icon: 'warning',
        title: 'Atenção',
        text: `Deseja realmente excluir o usuário ${user.name}?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          setIsLoading(true)

          $UserGroup.userGroup(userGroup.id).users.remove(user.id).then(({ data: userGroup }) => {
            setUserGroup(userGroup)
          }).catch(() => {
            SweetAlert.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Não foi possível excluir o usuário',
            })
          }).finally(() => setIsLoading(false))
        }
      })
    }
  }, isLoading)

  useEffect(() => {
    if (!id)
      return

    $UserGroup.find(Number(id)).then(({ data: userGroup }) => {
      setUserGroup(userGroup)
    }).finally(() => setIsLoading(false))
  }, [id])

  const data = useMemo(() => userGroup ? (
    userGroup.users
  ) : [], [userGroup])

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable<IUser>({
    columns,
    data,
  }, useSortBy)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!userGroup)
      return

    const usercode = Number(new FormData(event.currentTarget).get('usercode'))

    if (!usercode)
      return

    if (userGroup.users.find(user => user.usercode == usercode)) {
      SweetAlert.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Este usuário já está associado ao grupo',
      })

      return
    }

    setIsLoading(true)

    $UserGroup.userGroup(userGroup.id).users.add(usercode).then(({ data: userGroup }) => {
      setUserGroup(userGroup)
    }).catch(() => {
      SweetAlert.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Não foi possível associar o usuário, verfique se o RE informado é válido',
      })
    }).finally(() => setIsLoading(false))
  }

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <div className="title mb-3">
            <h1 className="mb-0">Multiusuários: Grupo {id}</h1>
            <span className="text-muted">{userGroup?.client.name}</span>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="d-flex justify-content-end mb-3">
            <Link to="/multiusuarios" className="btn btn-outline-primary">Voltar</Link>
          </div>
        </div>
      </div>

      {userGroup && (
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h6 className="fw-normal mb-0">Usuários ({userGroup.users.length})</h6>

            <form onSubmit={onSubmit}>
              <div className="input-group">
                <label className="input-group-text" htmlFor="usercode">RE</label>
                <input type="text" id="usercode" name="usercode" className="form-control" placeholder="Associar usuário" required />
                <button type="submit" className="btn btn-primary pe-3 ps-3" disabled={isLoading}>
                  Associar {isLoading && <Spinner animation="border" size="sm" className="ms-2" />}
                </button>
              </div>
            </form>
          </div>

          <div className="card-body p-0">
            <div className="table-responsive">
              <Table
                getTableProps={getTableProps}
                getTableHeadProps={getTableHeadProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                prepareRow={prepareRow}
                isLoading={isLoading}
                rows={rows}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UserGroup
