import React, { useMemo } from 'react'
import { CellProps, Column } from 'react-table'
import IUserGroup from '@interfaces/IUserGroup'
import { Link } from 'react-router-dom'

const useUserGroupColumns = () : Column<IUserGroup>[] => {
  return useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Cliente',
      accessor: 'users.0.client.name',
    },
    {
      Header: 'Escola',
      Cell: ({ row: { original: { users: [ { school } ] } } }: CellProps<IUserGroup, IUserGroup>) => {
        return (
          <>
            <div className="text-truncate">{school?.name}</div>
            <small className="text-muted">ID: {school?.id}</small>
          </>
        )
      },
    },
    {
      Header: 'Usuário',
      Cell: ({ row: { original: { users: [ user ] } } }: CellProps<IUserGroup, IUserGroup>) => {
        return (
          <>
            <div>{user.name}</div>
            <small className="text-muted">ID: {user.id}</small> &nbsp; <small className="text-muted">RE: {user.usercode}</small>
          </>
        )
      },
    },
    {
      Header: 'E-mail',
      accessor: 'users.0.email',
    },
    {
      Header: 'Ações',
      disableSortBy: true,
      Cell: ({ row: { original: userGroup } }: CellProps<IUserGroup, IUserGroup>) => (
        <div className="d-flex justify-content-end gap-3">
          <Link to={`/multiusuarios/${userGroup.id}`} className="btn btn-sm ps-3 pe-3 btn-primary">Editar</Link>
        </div>
      ),
      style: {
        width: 160,
        textAlign: 'right',
      },
    },
  ] as Column<IUserGroup>[], [])
}

export default useUserGroupColumns
