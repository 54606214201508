import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import IUserGroup from '@interfaces/IUserGroup'
import User from './User/User'
import Add from './Add/Add'
import $User from '@services/User'
import IUser from '@interfaces/IUser'

type Props = {
  user: IUser
}

const UserGroup: React.FC<Props> = ({ user }: Props) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ userGroup, setUserGroup ] = useState<IUserGroup | null>(null)

  useEffect(() => {
    $User.userGroup(user.id).then(({ data: userGroup }) => {
      if (userGroup) {
        setUserGroup(userGroup)
      }
    }).finally(() => setIsLoading(false))
  }, [user])

  return (
    <div className="card">
      <div className="card-header">Grupo de usuários</div>

      <div className="card-body p-0">
        {!isLoading ? (
          <table className="table table-striped table-hover mb-0">
            <thead>
              <tr>
                <th>ID</th>
                <th>RE</th>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Data de cadastro</th>
                <th className="text-right">Ações</th>
              </tr>
            </thead>

            <tbody>
              {userGroup && userGroup.users.filter(({ id }) => {
                return id !== user.id
              }).map((user, index) => (
                <User
                  user={user}
                  userGroup={userGroup}
                  key={`user-group-${index}-${user.id}`}
                  onLoad={setUserGroup}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="d-flex justify-content-center align-items-center p-3">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>

      <div className="card-footer">
        {userGroup && (
          <Add userGroup={userGroup} onLoad={setUserGroup} />
        )}
      </div>
    </div>
  )
}

export default UserGroup
