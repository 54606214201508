import IUserGroup from '@interfaces/IUserGroup'
import axios, { AxiosResponse } from 'axios'

const all = () : Promise<AxiosResponse<IUserGroup[]>> => {
  return axios.get('user-groups')
}

const find = (id: number) : Promise<AxiosResponse<IUserGroup>> => {
  return axios.get(`user-groups/${id}`)
}

const userGroup = (userGroupId: number) => {
  return {
    users: {
      add: (userCode: number) : Promise<AxiosResponse<IUserGroup>> => {
        return axios.post(`user-groups/${userGroupId}/users/${userCode}`)
      },
      remove: (userCode: number) : Promise<AxiosResponse<IUserGroup>> => {
        return axios.delete(`user-groups/${userGroupId}/users/${userCode}`)
      },
    },
  }
}

const $UserGroup = {
  all,
  find,
  userGroup,
}

export default $UserGroup
