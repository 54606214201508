import React, { useMemo } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import useWidgetColumns from '@hooks/useWidgetColumns'
import $Widget from '@services/Widget'
import useTable from '@hooks/useTable'
import Clients from './Clients/Clients'

const Widgets: React.FC = () => {
  const $widgets = useQuery('widgets', {
    queryFn: $Widget.all,
  })

  const columns = useWidgetColumns()

  const data = useMemo(() => {
    return $widgets.data || []
  }, [$widgets.data])

  const { Table, Pagination } = useTable(data, {
    columns,
    isLoading: $widgets.isLoading,
  })

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 col-md-4 mb-3">
          <h1 className="mb-0">Widgets</h1>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Início</Link>
            </li>

            <li className="breadcrumb-item active" aria-current="page">Widgets</li>
          </ol>
        </div>
      </div>

      <Tabs defaultActiveKey="widgets">
        <Tab title={`Widgets (${$widgets.data?.length ?? 0})`} eventKey="widgets">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <Table />
              </div>
            </div>

            <Pagination>
              {pagination => (
                <div className="card-footer d-flex justify-content-end">
                  {pagination}
                </div>
              )}
            </Pagination>
          </div>
        </Tab>

        <Tab title="Clientes" eventKey="clients">
          <Clients $widgets={$widgets} />
        </Tab>
      </Tabs>
    </>
  )
}

export default Widgets
