import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { MdClose, MdDragIndicator } from 'react-icons/md'
import { Container } from './Widget.styles'
import IWidget from '@interfaces/IWidget'
import { WidgetContext } from '../Editor'

const Widget: React.FC<IWidget> = (widget: IWidget) => {
  const { setWidgets } = useContext(WidgetContext)

  const widgetRef = useRef<HTMLDivElement>(null!)

  const onMousedown = useCallback((event: MouseEvent) => {
    const bounds = widgetRef.current.getBoundingClientRect()

    const x = event.clientX - bounds.left
    const y = event.clientY - bounds.top

    if (x >= bounds.width - 40 && y <= 40) {
      setWidgets(widgets => {
        return [
          ...widgets.filter(({ id }) => id !== widget.id),
        ]
      })
    }
  }, [setWidgets, widget.id])

  useEffect(() => {
    const widget = widgetRef.current

    widget.addEventListener('mousedown', onMousedown)

    return () => {
      widget.removeEventListener('mousedown', onMousedown)
    }
  }, [onMousedown])

  return (
    <Container ref={widgetRef} className="widget">
      <div className="card">
        <div className="card-header">
          {widget.isDraggable && (
            <button type="button" className="drag">
              <MdDragIndicator size={16} />
            </button>
          )}

          <h5 className="card-title mb-0">{widget.name}</h5>

          {!widget.isStatic && (
            <button type="button" className="close">
              <MdClose size={16} />
            </button>
          )}
        </div>
      </div>
    </Container>
  )
}

export default Widget
