import React, { useContext, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import IClient from '@interfaces/IClient'
import IWidget from '@interfaces/IWidget'
import AuthContext from '@contexts/Auth'
import { useMutation, UseQueryResult } from 'react-query'
import $Widget from '@services/Widget'
import useSwal from '@hooks/useSwal'
import { Widgets } from './Register.styles'
import { BiHelpCircle } from 'react-icons/bi'
import ReactTooltip from 'react-tooltip'
import { FiHelpCircle } from 'react-icons/fi'

type Props = {
  $widgets: UseQueryResult<IWidget[], unknown>
  data: (IClient & {
    widgets: Partial<IWidget>[];
  })[]
  show: boolean
  onHide: () => void
}

const Register: React.FC<Props> = ({ $widgets, data, show, onHide }) => {
  const { clients } = useContext(AuthContext)

  const [ selected, setSelected ] = useState<number[]>([])

  const swal = useSwal()

  const $store = useMutation($Widget.clients.store)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!$widgets.data)
      return

    const formData = new FormData(event.currentTarget)

    const clientId = Number(formData.get('clientId'))

    const widgets = $widgets.data.filter(({ id }) => {
      return formData.has(`widgets[${id}]`)
    }).map(({ id }) => {
      const query = formData.get(`widgets[${id}][query]`) as string

      return {
        id,
        query: query.length ? query : null,
      }
    })

    if (!Object.keys(widgets).length) {
      swal.fire({
        icon: 'error',
        title: 'Selecione pelo menos um widget',
      })

      return
    }

    $store.mutateAsync({
      clientId,
      widgets,
    }).then(() => {
      $widgets.refetch().finally(() => {
        setSelected([])
        onHide()
      })
    })
  }

  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = target

    if (checked) {
      setSelected([...selected, Number(value)])
    } else {
      setSelected(selected.filter(id => id !== Number(value)))
    }
  }

  const handleOnHide = () => {
    if (!$store.isLoading && !$widgets.isLoading) {
      setSelected([])
      onHide()
    }
  }

  return (
    <Modal show={show} onHide={handleOnHide} size="lg" centered>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Adicionar cliente</Modal.Title>
        </Modal.Header>

        <Modal.Body className="pb-0">
          <div className="form-group mb-3">
            <label htmlFor="clientId">*Cliente:</label>
            <select id="clientId" name="clientId" className="form-control" disabled={$store.isLoading || $widgets.isLoading} required>
              {clients.map(({ id, name }) => (
                <option key={id} value={id} disabled={data.some(client => client.id === id)}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          <Widgets className="widgets mb-3">
            <table className="table table-default table-bordered table-sm">
              <thead>
                <tr>
                  <th style={{ width: 40 }}></th>
                  <th>Widget</th>
                  <th>
                    Query <FiHelpCircle data-tip data-for="helpCircle" />
                  </th>
                </tr>
              </thead>

              <tbody>
                {$widgets.data?.map(({ id, name }) => (
                  <tr key={id}>
                    <td>
                      <input type="checkbox" id={`cb-widget-${id}`} name={`widgets[${id}]`} value={id} className="form-check-input" disabled={$store.isLoading || $widgets.isLoading} onChange={onChange} />
                    </td>

                    <td>
                      <label htmlFor={`cb-widget-${id}`}>{name}</label>
                    </td>

                    <td>
                      <input type="text" name={`widgets[${id}][query]`} className="form-control" disabled={$store.isLoading || $widgets.isLoading || !selected.includes(id)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <ReactTooltip id="helpCircle" place="top" effect="solid">
              O preenchimento deste campo sobrescreverá a query padrão do widget.
            </ReactTooltip>
          </Widgets>
        </Modal.Body>

        <Modal.Footer>
          <button type="submit" className="btn btn-primary" disabled={$store.isLoading || $widgets.isLoading || !selected.length}>
            Salvar {($store.isLoading || $widgets.isLoading) && <Spinner animation="border" size="sm" />}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default Register
