import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  position: relative;
  transition: opacity 0.1s ease;
  z-index: 2;

  > .card {
    height: 100%;
    border-radius: 0;
    position: relative;
    overflow: hidden;

    > .card-header {
      height: 40px;
      display: flex;
      align-items: center;
      position: relative;

      > .card-title {
        position: relative;
        top: 2px;
      }

      > .drag,
      > .close {
        background-color: transparent;
        color: var(--primary-color);
        padding: 0;
        border: 0;

        &:hover {
          color: var(--secondary-color);
        }
      }

      > .drag {
        width: 22px;
        height: 22px;
        cursor: grab;

        > svg {
          position: relative;
          left: -6px;
        }

        &:active {
          cursor: grabbing;
        }
      }

      > .close {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        display: flex;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      > .card-title {
        color: var(--primary-color);
      }
    }

    > .card-body {
      padding: 16px;
      scrollbar-width: auto;
      overflow-x: unset;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
        border: 1px solid #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border: 1px solid #fff;
        border-radius: 5px;
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: var(--primary-color);
        border-radius: 5px;
      }
    }
  }
`
