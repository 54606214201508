import IWidget from '@interfaces/IWidget'
import { useMemo, useState, } from 'react'
import ReactGridLayout from 'react-grid-layout'

export type Breakpoint = 'lg' | 'md' | 'sm' | 'xs' | 'xxs'

export const breakpoints = [
  'lg', 'md', 'sm', 'xs', 'xxs',
] as Breakpoint[]

export const cols = {
  lg: 12,
  md: 10,
  sm: 6,
  xs: 3,
  xxs: 2,
}

type UseWidgetsReturn = {
  widgets: IWidget[]
  setWidgets: React.Dispatch<React.SetStateAction<IWidget[]>>
  breakpoint: Breakpoint
  setBreakpoint: React.Dispatch<React.SetStateAction<Breakpoint>>
  getLayoutPosition: (widget: IWidget, breakpoint: Breakpoint) => { x: number, y: number }
  onBreakpointChange: (breakpoint: Breakpoint) => void
  onLayoutChange: (currentLayout: ReactGridLayout.Layout[]) => void
  layouts: ReactGridLayout.Layouts
}

const useWidgets = () : UseWidgetsReturn => {
  const [ widgets, setWidgets ] = useState<IWidget[]>([])
  const [ breakpoint, setBreakpoint ] = useState<Breakpoint>('lg')

  /**
   * Get layout position.
   *
   * @param {IWidget} widget
   * @param {Breakpoint} breakpoint
   *
   * @returns {{x: number, y: number} | null}
   */
  const getLayoutPosition = (widget: IWidget, breakpoint: Breakpoint) => {
    const size = cols[breakpoint]

    const layout = widget.layouts[breakpoint]

    const grid = Array(size).fill(0).map(() => (
      Array(100).fill(0)
    ))

    widgets.forEach(({ layouts }) => {
      const currentLayout = layouts[breakpoint]

      if (!currentLayout)
        return

      const { x, y, w, h } = currentLayout

      for (let i = x; i < x + w; i++) {
        for (let j = y; j < y + h; j++) {
          if (grid[i] && grid[i][j])
            grid[i][j] = 1
        }
      }
    })

    for (let y = 0; y < 100 - layout.h; y++) {
      for (let x = 0; x < size - layout.w; x++) {
        let fits = true

        for (let i = x; i < x + layout.w; i++) {
          for (let j = y; j < y + layout.h; j++) {
            if (grid[i] && grid[i][j] && grid[i][j] !== 0) {
              fits = false
              break
            }
          }

          if (!fits)
            break
        }

        if (fits) {
          return {
            x,
            y,
          }
        }
      }
    }

    return {
      x: (widgets.length * 2) % size,
      y: size,
    }
  }

  /**
   * On layout change.
   *
   * @param currentLayout
   * @param allLayouts
   * @returns void
   */
  const onLayoutChange = async (currentLayout: ReactGridLayout.Layout[]) => {
    setWidgets(widgets => {
      widgets.forEach(widget => {
        const layout = currentLayout.find(({ i }) => (
          i === widget.id.toString()
        ))

        if (!layout)
          return

        widget.layouts[breakpoint] = layout
      })

      return [
        ...widgets,
      ]
    })
  }

  const onBreakpointChange = (breakpoint: Breakpoint) => {
    setBreakpoint(breakpoint)
  }

  const layouts = useMemo(() => {
    return widgets.reduce((acc, widget) => {
      const { id, isStatic, isDraggable, isResizable } = widget

      breakpoints.forEach(breakpoint => {
        if (!acc[breakpoint]) {
          acc[breakpoint] = []
        }

        const layout = widget.layouts[breakpoint]

        if (!layout)
          return

        acc[breakpoint].push({
          ...layout,
          i: id.toString(),
          static: isStatic,
          isDraggable,
          isResizable,
        })
      })

      return acc
    }, {} as ReactGridLayout.Layouts)
  }, [widgets])

  return {
    widgets,
    setWidgets,
    breakpoint,
    setBreakpoint,
    getLayoutPosition,
    onBreakpointChange,
    onLayoutChange,
    layouts,
  }
}

export default useWidgets
