import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { usePagination, useSortBy, useTable } from 'react-table'
import * as $User from '@services/User'
import IUser from '@interfaces/IUser'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import useUserSchoolColumns from '@hooks/useUserSchoolColumns'
import Pagination from '@components/Pagination/Pagination'
import Table from '@components/Table/Table'
import Select from '@components/Select/Select'
import { Modal, Spinner } from 'react-bootstrap'
import AuthContext from '@contexts/Auth'
import axios from 'axios'
import IUserSchool from '@interfaces/IUserSchool'
import IClass from '@interfaces/IClass'
import * as $Class from '@services/Class'
import ISchool from '@interfaces/ISchool'
import * as $School from '@services/School'
import * as $Degree from '@services/Degree'
import IDegree from '@interfaces/IDegree'
import { now } from 'moment'
import { getCssProperty } from '@helpers/Theme'
import IClient from '@interfaces/IClient'
import { toInteger } from 'lodash'
import UserGroup from './UserGroup/UserGroup'
import IPermissionGroup from '@interfaces/IPermissionGroup'
import $PermissionGroup from '@services/PermissionGroup'

const roles = [
  { id: 1, name: 'Estudante' },
  { id: 2, name: 'Gestor' },
  { id: 3, name: 'Professor' },
  { id: 4, name: 'Corretor' },
]

const User: React.FC<any> = () => {
  const { admin, client, clients } = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [user, setUser] = useState<IUser | null>(null)
  const [search, setSearch] = useState<string>('')
  const [schoolId, setSchoolId] = useState<number>(0)
  const [userSchoolId, setUserSchoolId] = useState<number>(0)
  const [userSchools, setUserSchools] = useState<any[]>([])
  const [userSchool, setUserSchool] = useState<IUserSchool>({} as IUserSchool)
  const [userSchoolClasses, setUserSchoolClasses] = useState<IClass[]>([])
  const [showModal, setShowModal] = useState(false)
  const [schools, setSchools] = useState<ISchool[]>([])
  const [schoolIdModal, setSchoolIdModal] = useState<number>(0)
  const [degrees, setDegrees] = useState<IDegree[]>([])
  const [degreeId, setDegreeId] = useState<number>(0)
  const [classes, setClasses] = useState<IClass[]>([])
  const [classId, setClassId] = useState<number>(0)
  const [isLoadingInput, setIsLoadingInput] = useState<boolean>(false)
  const [permissionGroups, setPermissionGroups] = useState<IPermissionGroup[]>([])

  const { id: userId }: any = useParams()
  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    axios.all([
      $User.find(userId),
      $PermissionGroup.all(),
    ]).then(axios.spread(({ data: user }, { data: permissionGroups }) => {
      setUser(user)
      setPermissionGroups(permissionGroups)
    })).finally(() => setIsLoading(false))
  }, [userId])

  useEffect(() => {
    if (userSchoolId > 0 && schoolId > 0)
      axios.all([
        $User.schools(userId, userSchoolId, schoolId),
        $User.findUserSchool(userSchoolId),
        $Class.fromSchoolId(schoolId),
        $School.findByClient(client.id)
      ]).then(axios.spread(({ data: userSchools }: any, { data: userSchool }: any, { data: userSchoolClasses }: any, { data: schools }: any) => {
        setUserSchools(userSchools)
        setUserSchool(userSchool)
        setUserSchoolClasses(userSchoolClasses), setSchools(schools)
      })).finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId, userSchoolId])

  const userSchoolsOptions = useMemo(() => user?.userSchools?.map((userSchool: any) => ({
    value: [userSchool.school.id, userSchool.id],
    label: userSchool.school.id + ' - ' + userSchool.school.fantasyName,
  })), [user?.userSchools])

  useEffect(() => {
    if (userSchoolsOptions != null && userSchoolsOptions[0] != undefined)
      handleSelectChange(userSchoolsOptions[0])
  }, [userSchoolsOptions])

  const columns = useUserSchoolColumns((userSchool: any) => {
    $User.setAccessUser(userSchool.userSchoolId, userSchool.schoolId, admin.id, userSchool).then(() => SweetAlert.fire({
      title: 'Sucesso!',
      text: 'Usuário atualizado com sucesso!',
      icon: 'success',
    })).catch(() => SweetAlert.fire({
      title: 'Erro',
      text: 'Ocorreu um erro ao atualizar usuário!',
      icon: 'error',
    }))
  })

  const data = useMemo(() => {
    return userSchools.filter((userSchool: any) => {
      let exists = true

      if (search.length > 0) {
        exists = userSchool.name.toLowerCase().includes(search.toLowerCase())
      }

      return exists
    })
  }, [userSchools, search])

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Atualizando usuário',
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    const formData = new FormData(event.currentTarget)

    formData.set('active', formData.get('active') === '1' ? 'true' : 'false')

    const password = formData.get('password') as string

    if (password.length === 0) {
      formData.delete('password')
      formData.delete('passwordConfirmation')
    }

    $User.update(userId, formData).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Usuário atualizado com sucesso!',
        icon: 'success',
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Ocorreu um erro ao atualizar o usuário!',
        icon: 'error',
      })
    }).finally(() => setIsLoading(false))
  }

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => setUser({
    ...user,
    [name]: value,
  } as IUser)

  const handleSelectChange = ({ value }: any) => {
    setUserSchools([])
    setSchoolId(value[0])
    setUserSchoolId(value[1])
  }

  const handleInputChangeUserSchool = (e: any) => {
    const { name, value } = e.target

    setUserSchool({
      ...userSchool,
      [name]: value,
    })
  }

  const handleUpdateUserSchool = () => {
    if (userSchool.id == undefined) {
      setUserSchool({
        ...userSchool,
        userId: toInteger(user?.id),
        nickname: userSchool.nickname != undefined ? userSchool.nickname : user?.name ?? '',
      })
    }

    $User.updateUserSchool(admin.id, userSchool).then(() => SweetAlert.fire({
      title: 'Sucesso!',
      text: 'Usuário atualizado com sucesso!',
      icon: 'success',
    })).catch(() => SweetAlert.fire({
      title: 'Erro',
      text: 'Ocorreu um erro ao atualizar usuário!',
      icon: 'error',
    })).finally(() => SweetAlert.hideLoading())
  }

  const handleMigrated = () => {
    setShowModal(true)
    setSchoolIdModal(0)
    setDegreeId(0)
    setClassId(0)
  }

  const schoolsOptions = useMemo(() => schools.map((school: ISchool) => ({
    value: school.id,
    label: school.id + ' - ' + school.fantasyName,
  })), [schools])

  useEffect(() => {
    if (schoolIdModal > 0)
      axios.all([
        $Degree.all(schoolIdModal, new Date(now()).getFullYear()),
        $Class.fromSchoolId(schoolIdModal),
      ]).then(axios.spread(({ data: degrees }: any, { data: classList }: any) => {
        setDegrees(degrees)
        setClasses(classList)
      })).finally(() => setIsLoading(false))
  }, [schoolIdModal])

  const degreesOptions = useMemo(() => degrees.map((degree: IDegree) => ({
    value: degree.id,
    label: degree.name,
  })), [degrees])

  const classOptions = useMemo(() => classes.filter(((item: IClass) => item.degreeId == degreeId)).map((classroom: IClass) => ({
    value: classroom.id,
    label: classroom.name,
  })), [classes, degreeId])

  const handleSendMigrated = () => {
    SweetAlert.fire({
      title: 'Deseja prosseguir?',
      text: `Deseja realmente prosseguir com a migração do(o) ${user?.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: getCssProperty('--secondary-color'),
      cancelButtonColor: getCssProperty('--primary-color'),
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result: any) => {
      setShowModal(false)
      if (result.value) {
        SweetAlert.fire({
          title: 'Aguarde...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => SweetAlert.showLoading(),
        })

        setUserSchool({
          ...userSchool,
          schoolId: schoolIdModal,
          classId: classId
        })

        handleUpdateUserSchool()
      }
    })
  }

  const clientOptions = useMemo(() => clients.map((client: IClient) => ({
    value: client.id,
    label: client.id + ' - ' + client.name,
  })), [clients])

  const handleSetClient = ({ value: clientId }: any) => {
    setIsLoadingInput(true)
    $School.findByClient(clientId).then(({ data }: any) => setSchools(data)).finally(() => setIsLoadingInput(false))
  }

  const handleSchoolId = ({ value: schoolId }: any) => {
    setIsLoadingInput(true)
    axios.all([
      $Degree.all(schoolId, new Date(now()).getFullYear()),
      $Class.fromSchoolId(schoolId),
    ]).then(axios.spread(({ data: degrees }: any, { data: classList }: any) => {
      setDegrees(degrees)
      setUserSchoolClasses(classList)
    })).finally(() => setIsLoadingInput(false))

    setUserSchool({
      ...userSchool,
      schoolId: schoolId
    })
  }

  const handleClassId = ({ value: classId }: any) => {
    setUserSchool({
      ...userSchool,
      classId: classId
    })
  }

  const newClassOptions = useMemo(() => userSchoolClasses.map((classroom: IClass) => ({
    value: classroom.id,
    label: classroom.name + ' (' + degrees.find((degree: IDegree) => degree.id === classroom.degreeId)?.name + ' - ' + classroom.year + ')',
  })), [userSchoolClasses, degrees])

  return (
    <>
      {user && (
        <>
          <form onSubmit={onSubmit} autoComplete="off">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="title mb-3">
                  <h1 className="mb-0">{user?.name}</h1>
                  <span>Quantidade de escolas: {user?.schoolsCount}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="d-flex justify-content-end mb-3">
                  <Link to="/usuarios" className="btn btn-outline-primary me-3">Voltar</Link>
                  <button type="submit" className="btn btn-primary" disabled={isLoading}>Atualizar</button>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">Usuário</div>

              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-12 col-md-2">
                    <div className="form-group mb-3">
                      <label htmlFor="usercode">RE:</label>
                      <input type="text" id="usercode" value={user.usercode} className="form-control" disabled />
                    </div>
                  </div>

                  <div className="col-12 col-md-2">
                    <div className="form-group mb-3">
                      <label htmlFor="gestaoId">Gestão ID:</label>
                      <input type="text" id="gestaoId" value={user.gestaoId} className="form-control" disabled />
                    </div>
                  </div>

                  <div className="col-12 col-md-8">
                    <div className="form-group mb-3">
                      <label htmlFor="name">Nome:</label>
                      <input type="text" id="name" name="name" defaultValue={user.name} className="form-control" disabled={isLoading} required />
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group mb-3">
                      <label htmlFor="email">E-mail:</label>
                      <input type="email" id="email" name="email" defaultValue={user.email} className="form-control" disabled={isLoading} required={user.roleId !== 1} />
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group mb-3">
                      <label htmlFor="active">Status:</label>
                      <select id="active" name="active" defaultValue={user.active ? 1 : 0} className="form-control" disabled={isLoading} required>
                        <option value="1">Ativo</option>
                        <option value="0">Inativo</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group mb-3">
                      <label htmlFor="roleId">Cargo:</label>
                      <select id="roleId" name="roleId" defaultValue={user.roleId} className="form-control" disabled={isLoading} required>
                        {roles.map((role: any) => (
                          <option key={role.id} value={role.id}>{role.name}</option>
                        ))}
                      </select>

                      <span className="form-text text-muted">
                        * Alterar o cargo de um usuário pode causar problemas ao sistema.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">Permissões</div>

              <div className="card-body pb-0">
                {permissionGroups.map(({ permissions, ...group }) => (
                  <div className="permission-group mb-3" key={group.id}>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>{group.name}</th>
                          <th className="text-center">Ativo</th>
                        </tr>
                      </thead>

                      <tbody>
                        {permissions.map(permission => (
                          <tr key={permission.id}>
                            <td>
                              <label htmlFor={`permission-${permission.id}`} title={permission.name}>
                                {permission.description}
                              </label>
                            </td>

                            <td className="text-center">
                              <input
                                type="checkbox"
                                id={`permission-${permission.id}`}
                                name="permissions[]"
                                defaultChecked={user.permissions.some(({ id }) => id === permission.id)}
                                value={permission.id}
                                disabled={isLoading}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">Alteração de senha</div>

              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="password">Nova senha:</label>
                      <input type="password" id="password" name="password" className="form-control" autoComplete="new-password" onChange={handleInputChange} disabled={isLoading} />
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="passwordConfirmation">Confirmação de senha:</label>
                      <input
                        type="password"
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        className="form-control"
                        disabled={isLoading}
                        required={user.password != null && user.password.length > 0}
                        autoComplete="new-password"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="card mb-3">
            <div className="card-header">Escolas</div>

            <div className="card-body">
              {userSchoolsOptions && userSchoolsOptions.length > 0 ? (
                <>
                  <div className="row">
                    {userSchoolsOptions && (
                      <div className="col-12 col-md-6">
                        <div className="form-group mb-3">
                          <label htmlFor="school">Escola:</label>
                          <Select
                            type="primary"
                            defaultValue={userSchoolsOptions[0]}
                            onChange={handleSelectChange}
                            options={userSchoolsOptions}
                            placeholder="Selecione uma escola"
                            isDisabled={!user?.userSchools?.length}
                            isSearchable
                            required
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-8 col-md-6">
                      <div className="form-group">
                        <label htmlFor="search">Pesquisar:</label>
                        <input type="text" id="search" name="search" className="form-control mt-1"
                          placeholder="Pesquise pela prova" onChange={e => setSearch(e.target.value)} disabled={isLoading || data.length === 0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="nickName">Apelido:</label>
                        <input type="text" name="nickname" className="form-control mt-1"
                          defaultValue={userSchool?.nickname}
                          placeholder="Apelido"
                          onChange={handleInputChangeUserSchool}
                          disabled={isLoading || data.length === 0}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="identifier">RA:</label>
                        <input type="text" name="identifier" className="form-control mt-1"
                          defaultValue={userSchool?.identifier}
                          placeholder="RA"
                          onChange={handleInputChangeUserSchool}
                          disabled={isLoading || data.length === 0}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="classId">Turma:</label>
                        {userSchoolClasses.length > 0 ? (
                          <select name="classId" defaultValue={userSchool?.classId}
                            className="form-control mt-1"
                            disabled={isLoading || data.length === 0}
                            onChange={handleInputChangeUserSchool}
                          >
                            {userSchoolClasses.filter((item: IClass) => item?.deletedAt == null).map((item: IClass) => (
                              <option value={item.id} key={item.id}>{item.name} ({item.degreeName})</option>
                            ))}
                          </select>
                        ) : (
                          <input type="text" name="classId"
                            className="form-control mt-1"
                            defaultValue={userSchool.classId != 0 ? userSchool?.classId : ''}
                            onChange={handleInputChangeUserSchool} disabled={isLoading || data.length === 0}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-3 mt-4">
                      <button className="btn btn-primary" style={{ marginTop: '1%' }} disabled={isLoading || data.length === 0} onClick={handleUpdateUserSchool}>Atualizar</button>
                      <button className="btn btn-outline-danger ms-2" style={{ marginTop: '1%' }} disabled={isLoading || data.length === 0} onClick={handleMigrated}>Migrar de escola</button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="table-responsive">
                      {data.length > 0 ? (
                        <Table
                          getTableProps={getTableProps}
                          getTableHeadProps={getTableHeadProps}
                          getTableBodyProps={getTableBodyProps}
                          page={page}
                          headerGroups={headerGroups}
                          prepareRow={prepareRow}
                          isLoading={isLoading}
                          placeholder="Nenhuma escola encontrada."
                        />
                      ) : (userSchoolId > 0 && schoolId > 0 &&
                        <div className="d-flex justify-content-center p-3 bg-light mt-4">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-4 mt-1">
                      <div className="form-group">
                        <label htmlFor="clientId">Cliente:</label>
                        <Select
                          type="primary"
                          onChange={handleSetClient}
                          options={clientOptions}
                          isSearchable={true}
                          placeholder="Selecione um cliente"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mt-1">
                      <div className="form-group">
                        <label htmlFor="schoolId">Escola:</label>
                        {schools.length > 0 ? (
                          <Select
                            type="primary"
                            onChange={handleSchoolId}
                            options={schoolsOptions}
                            isSearchable={true}
                            placeholder="Selecione uma escola"
                          />
                        ) : isLoadingInput && schools.length == 0 ?
                          (<div className="d-flex justify-content-start" style={{ marginTop: '2%' }}>
                            <Spinner animation="border" variant="primary" size="sm" />
                          </div>) : (<input className="form-control mt-1" placeholder="Selecione uma escola" disabled={true} />)}
                      </div>
                    </div>
                    <div className="col-md-4 mt-1">
                      <div className="form-group">
                        <label htmlFor="classId">Turma:</label>
                        {userSchoolClasses.length > 0 ? (
                          <Select
                            type="primary"
                            onChange={handleClassId}
                            options={newClassOptions}
                            isSearchable={true}
                            placeholder="Selecione uma turma"
                          />
                        ) : isLoadingInput && userSchool.schoolId != undefined ?
                          (<div className="d-flex justify-content-start" style={{ marginTop: '2%' }}>
                            <Spinner animation="border" variant="primary" size="sm" />
                          </div>) : (<input className="form-control mt-1" placeholder="Selecione uma turma" disabled={true} />)}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-md-2">
                    <div className="col-md-4 mt-1">
                      <div className="form-group">
                        <label htmlFor="nickName">Apelido:</label>
                        <input type="text" name="nickname" className="form-control mt-1"
                          defaultValue={user?.name}
                          placeholder="Apelido"
                          onChange={handleInputChangeUserSchool}
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mt-1">
                      <div className="form-group">
                        <label htmlFor="identifier">RA:</label>
                        <input type="number" name="identifier" className="form-control mt-1"
                          placeholder="RA"
                          onChange={handleInputChangeUserSchool}
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mt-md-4">
                      <button className="btn btn-primary" style={{ marginTop: '1%' }}
                        disabled={isLoading || (user.roleId == 1 ?
                          (userSchool.identifier == undefined || userSchool.identifier <= 0 || userSchool.classId == undefined || userSchool.classId == 0) : false) || userSchool?.nickname?.trim().length == 0} onClick={handleUpdateUserSchool}
                      >Cadastrar</button>
                    </div>
                  </div>
                </>
              )}
            </div>

            {pageCount > 1 && (
              <div className="card-footer d-flex justify-content-end">
                <Pagination
                  page={pageIndex}
                  pageCount={pageCount}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  onClick={(page: number) => gotoPage(page)}
                />
              </div>
            )}
          </div>

          {user && (
            <UserGroup user={user} />
          )}

          <Modal
            backdrop="static"
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Migrar usuário</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mt-3">
              <div className="row mb-3">
                <div className="col-12">
                  <div className="form-group mb-3">
                    <label htmlFor="school">Escolas ({client.name}): </label>
                    <Select
                      type="primary"
                      onChange={(value: any) => setSchoolIdModal(value.value)}
                      options={schoolsOptions}
                      placeholder="Selecione uma escola"
                      isDisabled={!schools}
                      isSearchable
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="school">Série:</label>
                    <Select
                      type="primary"
                      onChange={(value: any) => setDegreeId(value.value)}
                      options={degreesOptions}
                      placeholder="Selecione uma série"
                      isDisabled={!degreesOptions || schoolIdModal == 0}
                      isSearchable
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="school">Turma:</label>
                    <Select
                      type="primary"
                      onChange={(value: any) => setClassId(value.value)}
                      options={classOptions}
                      placeholder="Selecione uma turma"
                      isDisabled={!classOptions || degreeId == 0}
                      isSearchable
                      required
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-12 d-flex justify-content-between">
                <button className="btn btn-outline-primary" onClick={() => setShowModal(false)}>Cancelar</button>
                <button className="btn btn-primary" disabled={schoolIdModal == 0} onClick={handleSendMigrated}>Migrar</button>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  )
}

export default User
