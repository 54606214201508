import styled from 'styled-components'

export const Widgets = styled.div`
  > .table {
    > thead > tr > th {
      font-weight: 500;
      font-size: 1rem;
    }

    > tbody {
      > tr {
        > td {
          vertical-align: middle;
          padding: 0.5rem 1rem;

          > .input-group {
            display: flex;
            align-items: center;

            > .input-group-prepend {
              display: flex;
              align-items: center;
              justify-content: center;
              align-content: stretch;

              > .input-group-text {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: 0;
              }
            }
          }
        }
      }
    }
  }
`
