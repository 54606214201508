import React, { useMemo } from 'react'
import IWidget from '@interfaces/IWidget'
import { Link } from 'react-router-dom'
import { CellProps, Column } from 'react-table'

const useWidgetColumns = () : Column<IWidget>[] => {
  return useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
      style: {
        width: 60,
      },
    },
    {
      Header: 'Nome',
      accessor: 'name',
    },
    {
      Header: 'Categoria',
      accessor: ({ category }: IWidget) => category.name,
    },
    {
      Header: 'Ações',
      disableSortBy: false,
      Cell: ({ row: { original: { id } } }: React.PropsWithChildren<CellProps<IWidget, IWidget>>) => {
        return (
          <div className="d-flex justify-content-end">
            <Link to={`/widgets/${id}`} className="btn btn-sm ps-3 pe-3 btn-primary me-2">
              Editar
            </Link>
          </div>
        )
      },
      style: {
        width: 160,
        textAlign: 'right',
      },
    },
  ], [])
}

export default useWidgetColumns
