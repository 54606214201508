import React, { useMemo, useState } from 'react'
import { useMutation, UseQueryResult } from 'react-query'
import IWidget from '@interfaces/IWidget'
import IClient from '@interfaces/IClient'
import useTable from '@hooks/useTable'
import useWidgetClientColumns from '@hooks/useWidgetClientColumns'
import $Widget from '@services/Widget'
import Register from './Register/Register'

type Props = {
  $widgets: UseQueryResult<IWidget[], unknown>
}

const Clients: React.FC<Props> = ({ $widgets }) => {
  const [ show, setShow ] = useState(false)

  const $delete = useMutation($Widget.clients.delete)

  const onDelete = (id: number) => {
    $delete.mutateAsync(id).finally(() => {
      $widgets.refetch()
    })
  }

  const columns = useWidgetClientColumns(onDelete)

  const data = useMemo(() => {
    if (!$widgets.data)
      return []

    const clients = $widgets.data.reduce((clients, widget) => {
      widget.widgetClients.forEach(({ client }) => {
        if (!client)
          return

        if (!clients[client.id]) {
          clients[client.id] = {
            ...client,
            widgets: [],
          }
        }

        const { id, name } = widget

        clients[client.id].widgets.push({ id, name })
      })

      return clients
    }, {} as Record<number, IClient & { widgets: Partial<IWidget>[] }>)

    return Object.values(clients)
  }, [$widgets.data])

  const { Table, Pagination } = useTable(data, {
    columns,
    isLoading: $widgets.isLoading,
  })

  return (
    <>
      <Register
        $widgets={$widgets}
        data={data}
        show={show}
        onHide={() => setShow(false)}
      />

      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="card-title mb-0">Clientes</div>

          <button type="button" className="btn btn-sm btn-primary pe-3 ps-3 mb-0" onClick={() => setShow(true)}>
            Adicionar
          </button>
        </div>

        <div className="card-body p-0">
          <div className="table-responsive">
            <Table />
          </div>
        </div>

        <Pagination>
          {pagination => (
            <div className="card-footer d-flex justify-content-end">
              {pagination}
            </div>
          )}
        </Pagination>
      </div>
    </>
  )

  return null
}

export default Clients
