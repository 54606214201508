import React, { useMemo } from 'react'
import { CellProps, Column } from 'react-table'
import { Link } from 'react-router-dom'
import IWidget from '@interfaces/IWidget'
import IClient from '@interfaces/IClient'

export type WidgetClient = IClient & { widgets: Partial<IWidget>[] }

const useWidgetClientColumns = (onDelete: (id: number) => void) : Column<WidgetClient>[] => {
  return useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
      style: {
        width: 60,
      },
    },
    {
      Header: 'Cliente',
      accessor: 'name',
    },
    {
      Header: 'Widgets',
      accessor: ({ widgets }) => (
        <div className="text-truncate">
          {widgets.map(({ id, name }) => (
            <span className="badge bg-secondary me-1" key={id}>
              {name}
            </span>
          ))}
        </div>
      ),
    },
    {
      Header: 'Ações',
      disableSortBy: false,
      Cell: ({ row: { original: { id } } }: React.PropsWithChildren<CellProps<WidgetClient, WidgetClient>>) => {
        return (
          <div className="d-flex justify-content-end">
            <Link to={`/widgets/clients/${id}`} className="btn btn-sm ps-3 pe-3 btn-primary me-2">
              Editar widgets
            </Link>

            <button type="button" className="btn btn-sm ps-3 pe-3 btn-danger" onClick={() => onDelete(id)}>
              Excluir
            </button>
          </div>
        )
      },
      style: {
        width: 160,
        textAlign: 'right',
      },
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])
}

export default useWidgetClientColumns
