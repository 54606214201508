import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import $Widget from '@services/Widget'

const Widget: React.FC = () => {
  const [ isStatic, setIsStatic ] = useState(false)

  const { id } = useParams<{ id: string }>()

  const isDraggableRef = useRef<HTMLSelectElement>(null)
  const isResizableRef = useRef<HTMLSelectElement>(null)

  const queryClient = useQueryClient()

  const $widget = useQuery(['widgets', Number(id)], {
    queryFn: $Widget.find,
  })

  const $update = useMutation($Widget.update)

  const widget = useMemo(() => {
    return $widget.data || null
  }, [$widget.data])

  const onIsStatic = useCallback(() => {
    if (!isDraggableRef.current || !isResizableRef.current)
      return

    isDraggableRef.current.value = '0'
    isResizableRef.current.value = '0'

    setIsStatic(true)
  }, [])

  useEffect(() => {
    if (!widget)
      return

    const { isStatic } = widget

    if (isStatic) {
      onIsStatic()
    }
  }, [onIsStatic, widget])

  const onIsStaticChange = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    if (value === '1') {
      onIsStatic()
    } else {
      setIsStatic(false)
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)

    const name = formData.get('name') as string
    const isStatic = formData.get('isStatic') === '1'
    const isDraggable = formData.get('isDraggable') === '1'
    const isResizable = formData.get('isResizable') === '1'

    $update.mutateAsync([
      Number(id),
      {
        name,
        isStatic,
        isDraggable,
        isResizable,
      },
    ]).then(() => {
      queryClient.refetchQueries([
        'widgets',
      ])
    })
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="row align-items-center">
        <div className="col-12 col-md-6 mb-3">
          <h1 className="mb-0">{widget?.name}</h1>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Início</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/widgets">Widgets</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">{widget?.name}</li>
          </ol>
        </div>

        <div className="col-12 col-md-6 mb-3">
          <div className="d-flex align-items-center justify-content-end gap-3">
            <Link to="/widgets" className="btn btn-outline-secondary pe-3 ps-3">Voltar</Link>

            <button type="submit" className="btn btn-primary pe-3 ps-3" disabled={$update.isLoading}>
              Atualizar {$update.isLoading && <Spinner animation="border" size="sm" className="ml-2" />}
            </button>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">Configurações do widget</div>

        {widget && (
          <div className="card-body pb-0">
            <div className="row">
              <div className="col-12 mb-3">
                <div className="form-group">
                  <label htmlFor="name">*Título:</label>
                  <input type="text" name="name" className="form-control" defaultValue={widget.name} disabled={$update.isLoading} required />
                </div>
              </div>

              <div className="col-12 col-md-4 mb-3">
                <div className="form-group">
                  <label htmlFor="isStatic">É estático:</label>
                  <select name="isStatic" className="form-control" defaultValue={widget.isStatic ? '1' : '0'} onChange={onIsStaticChange} disabled={$update.isLoading}>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </div>
              </div>

              <div className="col-12 col-md-4 mb-3">
                <div className="form-group">
                  <label htmlFor="isDraggable">Pode mover:</label>
                  <select ref={isDraggableRef} name="isDraggable" className="form-control" defaultValue={widget.isDraggable ? '1' : '0'} disabled={$update.isLoading || isStatic}>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </div>
              </div>

              <div className="col-12 col-md-4 mb-3">
                <div className="form-group">
                  <label htmlFor="isResizable">Pode redimensionar:</label>
                  <select ref={isResizableRef} name="isResizable" className="form-control" defaultValue={widget.isResizable ? '1' : '0'} disabled={$update.isLoading || isStatic}>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="form-group">
                  <label htmlFor="query">*Query:</label>
                  <textarea name="query" className="form-control" defaultValue={widget.query} disabled={$update.isLoading} required />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  )
}

export default Widget
