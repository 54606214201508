import React, { createContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import $Client from '@services/Client'
import useWidgets, { Breakpoint, cols } from '@hooks/useWidgets'
import IWidget from '@interfaces/IWidget'
import { Responsive, WidthProvider } from 'react-grid-layout'
import Widget from './Widget/Widget'

type Props = {
  widgets: IWidget[]
  setWidgets: React.Dispatch<React.SetStateAction<IWidget[]>>
  breakpoint: Breakpoint
  setBreakpoint: React.Dispatch<React.SetStateAction<Breakpoint>>
}

const ResponsiveGridLayout = WidthProvider(Responsive)

export const WidgetContext = createContext<Props>({ } as Props)

const Editor: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const { widgets, setWidgets, breakpoint, setBreakpoint, onLayoutChange, onBreakpointChange, layouts } = useWidgets()

  const $widgets = useQuery({
    queryFn: $Client.widgets,
    queryKey: [
      'client-widgets', Number(id),
    ],
  })

  useEffect(() => {
    if ($widgets.data) {
      setWidgets($widgets.data)
    }
  }, [$widgets.data, setWidgets])

  return (
    <WidgetContext.Provider
      value={{
        widgets,
        setWidgets,
        breakpoint,
        setBreakpoint,
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="page-header">
          <h1 className="mb-0">Editor de widgets</h1>

          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Início</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/widgets">Widgets</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">Editor de widgets</li>
          </ol>
        </div>

        <div className="d-flex align-items-center gap-2">
          <Link to="/widgets" className="btn btn-outline-secondary pe-3 ps-3">
            Voltar
          </Link>

          <button type="button" className="btn btn-primary pe-3 ps-3">
            Adicionar widget
          </button>

          <button type="button" className="btn btn-primary pe-3 ps-3">
            Acessar
          </button>

          <button type="button" className="btn btn-primary pe-3 ps-3">
            Salvar
          </button>
        </div>
      </div>

      {layouts && (
        <ResponsiveGridLayout
          rowHeight={100}
          margin={[16, 16]}
          cols={cols}
          layouts={layouts}
          onLayoutChange={onLayoutChange}
          onBreakpointChange={onBreakpointChange}
          style={{
            marginLeft: '-16px',
            marginRight: '-16px',
          }}
        >
          {widgets.map(widget => {
            return (
              <div className="grid-item" key={widget.id}>
                <Widget {...widget} />
              </div>
            )
          })}
        </ResponsiveGridLayout>
      )}
    </WidgetContext.Provider>
  )
}

export default Editor
